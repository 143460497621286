h1{
  font-size: 2rem;
  font-weight: 700;
}
h2 {
  font-size: 1.5rem;
  line-height: 1.25;
}

.btn-form {
  display: inline-block;
}
.alert {
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 20px;
  width: max-content;
}

.alert-header {
  font-size: 1.25rem;
  font-weight: 600;
}

.mdl-textfield {
  width: 400px;
}

.bg-gray {
  background-color: #EFF3F9 !important;
}
.bg-white {
  background-color: #FFFFFF !important;
}


/*MDL*/

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 0px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
.hamburger:hover {
  opacity: 0.7; }
.hamburger.is-active:hover {
  opacity: 0.7; }
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #4D5267; }

.hamburger-box {
  width: 33px;
  height: 18px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 60%;
  margin-top: -1px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 33px;
  height: 2px;
  background-color: #4D5267;
  border-radius: 4px;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }
.hamburger-inner::before {
  top: -8px; }
.hamburger-inner::after {
  bottom: -8px; }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
  transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spin .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.25s ease-in,
  opacity 0.1s ease-in;
  transition: top 0.1s 0.25s ease-in,
  opacity 0.1s ease-in; }
.hamburger--spin .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.1s ease-out,
  opacity 0.1s 0.12s ease-out;
  transition: top 0.1s ease-out,
  opacity 0.1s 0.12s ease-out; }
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger {
  display: none;
  position: absolute;
  right: 0;
  outline: none; }
@media screen and (max-width: 750px) {
  .hamburger {
    display: inline-block; }
  .group-btns {
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
  }

}
.hamburger.is-active {
  position: relative;
  z-index: 11;
  position: absolute;
  right: 0; }



.group-btns {
  display: flex;
  margin-bottom: 30px;
}
.group-btns-item {
  display: inline-flex;
  margin: 2px;
}
.group-btns-item > a {
  padding: 10px 15px;
}

.group-btns-item > a:hover {
  cursor: pointer;
}



/*ACCORDION*/

details[open] summary ~ * {
  animation: open 0.5s ease-in-out;
}
details[open] summary {
  color: #5583de;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
details summary::-webkit-details-marker {
  display: none;
}

details {
  margin: 2px;
  border-radius: 3px;
}

details summary {
  width: 100%;
  padding: 10px 50px 10px 20px;
  /*margin: 2px;*/
  /*border: 1px solid #eee;*/
  /*border-radius: 3px;*/
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 300;
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}

details summary:after {
  content: "+";
  color: #5583de;
  position: absolute;
  font-size: 1.25rem;
  line-height: 0;
  /*margin-top: 0.75rem;*/
  right: 15px;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}
details[open] summary:after {
  transform: rotate(45deg);
  font-size: 1.5rem;
}
details summary {
  outline: 0;
}
details p {
  font-size: 0.95rem;
  margin: 0 25px 1rem;
  padding-top: 1rem;
}
.text-right {
  text-align: right;
}
pre {
  margin: 0.5em 0;
}
/* .navigation-ancors__item .contacts {
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

@media screen and (max-width: 750px) {
  .contacts {
    display: none;
  }
  .mobile-visible {
    display: block;
    margin-right: 50px !important;
    width: 100%;
    text-align: center;
    font-size: 15px;
    margin-left: 5px;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 750px) {
  .mobile-visible {
    display: none;
  }
}
