.workers {
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 100px;
}
@media (max-width: 600px) {
    .workers {
        flex-direction: column;
    }
}
.worker {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.worker > img {
    width: 200px;
    height: 200px;
}
.worker > span {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.downloads > li {
    flex-basis: 100%;
}
.downloads > li > a {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.downloads > li > a > span:first-child {
    color: rgb(105, 105, 105);
}
.downloads > li > a > span:last-child {
    color: #5583de;
}
html {
    scroll-behavior: smooth;
}