.products {
    margin-top: 20px;}
@media screen and (max-width: 750px) {
    .products > h2 {
        line-height: 1;

    }
}
.products .container h2 {
    font-weight: 500;
    font-size: 1.94444rem;
    margin-top: 70px; }
.products .container p.subtitle {
    margin-bottom: 0; }
.products .container .products img {
    width: 100%;
    /*height: -webkit-min-content;*/
    /*height: -moz-min-content;*/
    height: auto;
    max-width: 300px;
    margin-bottom: 30px; }
.products .container .products .product-item {
    padding: 5px;
    font-size: 15px;
    text-align: justify; }
.container .products .product-item .card {
    margin: 5px;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #ddd;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.container .products .product-item .card > label {
    margin-top: 15px;
    align-self: center;
}
.container .products .product-item .card h3, .products .container .products .product-item .card h2 {
    text-align: left;
    font-size: 1.15rem;
    margin: 10px 0;
    line-height: 1.25;
    font-weight: 600;
    min-height: 80px;
    display: flex;
    align-items: flex-start;}
@media screen and (max-width: 768px) {
    .container .products .product-item .card h3, .products .container .products .product-item .card h2 {
        min-height: unset;
    }
    .container .products .product-item .card ul {
        min-height: unset !important;
    }
}
.container .products .product-item .card ul {
    margin: 10px 0px 0px 0px;
    text-align: justify;
    font-size: 0.75rem;
    min-height: 110px;}
.container .products .product-item .card ul.dops {
    min-height: unset;}
.container .products .product-item .card ul li {
    background: url('../img/icon-check-primary.svg') no-repeat left center;
    padding: 0px 0px 0px 25px;
    list-style: none;
    margin: 0;
    vertical-align: middle;
    /*list-style-image: url("../img/icon-check-primary.svg"); */
}
.container .products .product-item .card a {
    padding: 15px 10px;
    margin: auto;
    margin-top: 20px;
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
@media screen and (max-width: 750px) {
    .container .products .product-item .card a {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; } }

.product-image {
    height: fit-content;
    /*max-height: 200px;*/
    /*max-width: 200px !important;*/
    margin: auto;
}

p.price {
    text-align: left;
    font-size: 18px;
    color: rgb(85 131 222);
    font-weight: bold; }