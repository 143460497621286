.text-16 {
    font-size: 16px;
}
.mr-15 {
    margin-right: 15px;
}
.ozon-buy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #5583DE;
}
.ozon-buy-btn > span {
    white-space: nowrap;
    margin-right: 15px;
}
.ozon-buy-btn > img {
    width: auto;
    height: 20px;
}
.logo-text {
    font-weight: bold;
    color: white;
    /*padding: 5px 0;*/
    font-size: 25px;
    line-height: 1;
}

.light{
    overflow: hidden;
    /*z-index: 1;*/
    position: relative;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}
.light:hover {
    box-shadow: 0px 4px 4px rgba(100,160,255,0.25);
    cursor: pointer;
    /*color: #ffffff;*/
}
.light:before {
    webkit-transform: skewX(-45deg) translateX(13.5em);
    transform: skewX(-50deg) translateX(25em);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    /* animation-delay: 2s; */
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: 2em;
    -webkit-transform: skewX(-45deg) translateX(0);
    transform: skewX(-45deg) translateX(0);
    -webkit-transition: none;
    transition: none;
    animation: moving 2s ease-in-out infinite;
}

@keyframes moving {
    30% {  webkit-transform: skewX(-45deg) translateX(13.5em);
        transform: skewX(-45deg) translateX(22.5em);

    }

    100% {  webkit-transform: skewX(-45deg) translateX(13.5em);
        transform: skewX(-45deg) translateX(22.5em);

    }

}

.header-btns {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
}
@media (max-width: 650px) {
    .header-btns {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
@media (max-width: 991px) {
    .header-btns {
        justify-content: center;
    }
}

.hero__visual-content > img.background {
    position: absolute;
    width: 627px;
    height: auto;
    top: 0;
    left: 50%;
    pointer-events: none;
}
.hero__visual-content > img.image {
    position: relative;
    z-index: 1;
}
@media (max-width: 1250px) {
    .hero__visual-content > img.background {
        display: none;
    }
}