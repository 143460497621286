.text-16 {
    font-size: 16px;
}
.mr-15 {
    margin-right: 15px;
}
.ozon-buy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #5583DE;
}
.ozon-buy-btn > span {
    white-space: nowrap;
    margin-right: 15px;
}
.ozon-buy-btn > img {
    width: auto;
    height: 20px;
}
.call-btn {
    padding: 0;
}
.call-btn > a {
    padding: 10px 20px;
    display: inline-block;
}

.user-image {
    width: 150px;
}
.review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
}

.list-with-immage > li {
    display: flex;
    margin-bottom: 20px;
}
.list-with-immage > li > img{
    width: 30px;
    margin-right: 15px;
    margin-bottom: 10px;
}
.panels {
    display: flex;
    flex: 1 0;
    justify-content: center;
    flex-wrap: wrap;
    /*justify-content: center;*/
}
.panels > .panel {
    background-color: white;
    border-radius: 20px;
    margin: 7px;
    box-shadow: none;
    flex: 1 0;
    text-align: center;
    flex-direction: column;
    display: flex;
    border: 2px solid #eee;
}
.panels-4 > .panel {
    min-width: 275px;
    max-width: 300px;
}
.panels-5 > .panel {
    min-width: 225px;
    max-width: 250px;
}
.panels > .panel > img {
    max-width: 150px;
    margin: 0 auto;
}

.addonBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.addonBlock > img {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    margin-right: 20px;
    background: #fff;
}
ul.dotted-list {
    flex: 1 0;
    font-size: 15px;
}
@media screen and (max-width: 850px) {
    ul.dotted-list.not-dotted-onmobile {
        font-size: 12px;
    }
    ul.dotted-list.not-dotted-onmobile li div:nth-child(2) {
        display: none;
    }
    ul.dotted-list.not-dotted-onmobile li div:first-child {
        font-weight: 700;
        line-height: 1;
    }
    ul.dotted-list.not-dotted-onmobile li div:last-child {
        margin-bottom: 10px;
    }
    .addonBlock {
        padding-bottom: 10px;
        border-bottom: 1px solid #DADEE4;
    }
    .addonBlock > img {
        width: 70px;
        height: 70px;
    }
    ul.dotted-list.not-dotted-onmobile li {
        flex-direction: row;
        align-items: baseline;
    }
    ul.dotted-list li div:nth-child(2) {
        min-width: 25px;
    }
    ul.dotted-list li div:last-child {
        text-align: right;
        white-space: nowrap;
    }
}

ul.dotted-list li {
    list-style: none;
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 450px) {
    ul.dotted-list li {
        padding: 5px 0px;
        align-items: center;
    }
    ul.dotted-list li div:last-child {
        white-space: unset;
    }
    ul.not-dotted-onmobile.direction-col > li {
        flex-direction: column !important;
        border-bottom: 1px solid #DADEE4;
        padding-bottom: 0;
        padding-top: 10px;
    }
    ul.dotted-list.not-dotted-onmobile.direction-col li div:last-child {
        margin-bottom: 0px;
    }
    .addonBlock {
        border-bottom: none;
    }
/*    ul.striped.dotted-list li:nth-child(odd){
        background: #f3f8ff;
    }
    ul.striped.dotted-list li:nth-child(even){
        background: #FFFFFF;
    }*/
}

ul.dotted-list li div:nth-child(2) {
    flex: 1 0;
    border-bottom: 2px dotted #555;
    height: .5em;
    margin: 0.5em;
}