.footer {
    font-size: 0.77778rem;
    line-height: 26px;
    background-color: #34393f;
    color: #E5E5E5; }

.footer__text > p {
    line-height: 1;
    margin-bottom: 5px;
}

.footer__logo {
    width: 10rem;
    margin-top: 25px; }

.footer__main {
    margin-top: 2.22222rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%; }
@media screen and (max-width: 750px) {
    .footer__main {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; } }

.footer__icon-pic {
    width: 126px;
    height: 39px; }

.footer__main-text {
    width: 100%;
    margin-left: 2rem; }
@media screen and (max-width: 768px) {
    .footer__main-text {
        margin-left: 1.11111rem; } }
@media screen and (max-width: 750px) {
    .footer__main-text {
        margin-left: 0; } }

.footer__nav {
    width: 100%; }

.footer__nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
@media screen and (max-width: 768px) {
    .footer__nav-list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; } }
.footer__nav-list .footer__nav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 25px; }
.footer__nav-list .footer__nav-item h4 {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border-bottom: 1px solid #777;}
.footer__nav-item p {
    line-height: 1.5;
    font-size: 12px;
    text-align: left;
}
.footer__nav-list .footer__nav-item a, .footer__nav-list .footer__nav-item span, .footer__nav-list .footer__nav-item label {
    color: #E5E5E5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 5px 0;
    font-size: 12px;
    line-height: 1.15;}
.footer__nav-list .footer__nav-item a svg {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.footer__copy {
    margin-top: 1.11111rem; }