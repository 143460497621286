.benefit-card {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    box-shadow: 0 0 1px 1px #eee;
    text-align: center;
}
.benefit-img {
    width: 100px;
    margin-bottom: 15px !important;
}
.benefit-text {
    text-align: center;
    line-height: 1.15;
    font-size: 15px;
}
.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 115px;
}


.benefit-item {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    justify-content: center;
}
.benefit-item img, .benefit-item svg {
    margin: auto;
    margin-bottom: 20px;
    width: 70px;
    height: 70px; }