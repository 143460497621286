#send > .modal__wrapper {
    width: 500px;
}
#send > .modal__wrapper > .modal__content > form {
    max-width: 450px;
    margin: auto;
}
.form-result-container {
    display: flex;
    align-items: center;
}
.form-result-container > span {
    margin-left: 10px;
    font-size: 14px;
    display: none;
    line-height: 1;
    max-width: 255px;
}