@font-face {
  font-family: "Mull-med";
  src: url("./fonts/MullerMedium.woff") format("woff"), url("./fonts/MullerMedium.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Mull-reg";
  src: url("./fonts/MullerRegular.eot") format("eot"), url("./fonts/MullerRegular.woff") format("woff"), url("./fonts/MullerRegular.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Mull-bold";
  src: url("./fonts/MullerBold.eot") format("eot"), url("./fonts/MullerBold.woff") format("woff"), url("./fonts/MullerBold.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Roboto-med";
  src: url("./fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Roboto-reg";
  src: url("./fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Roboto-bold";
  src: url("./fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-display: swap; }

html, body {
  height: 100%;
  width: 100%;
  font-size: 18px;
  scroll-behavior: smooth; }

body {
  font-family: 'Roboto-reg', Arial, sans-serif;
  -webkit-font-snoothing: antialiased;
  -moz-osx-font-snoothing: grayscale;
  color: #34393F;
  line-height: 1.42;
  font-size: 1rem;
  margin: 0 !important;
}
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 4px;
}
::-webkit-scrollbar-track-piece {
  background: #e3e3e3;
}
@media screen and (max-width: 420px){
  ::-webkit-scrollbar {
    width: 0;
  }}
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Roboto-reg', Arial, sans-serif; }

h1 {
  font-size: 2.66667rem; }

ul {
  margin: 0;
  padding: 0; }

ul li {
  list-style: none; }

p {
  margin: 5px 0;
  text-align: justify;
  font-size: 16px; }

button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: #000; }

img {
  width: 100%; }

section {
  padding-top: 70px !important; }
section h2 {
  text-align: center; }

.wrapper {
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative; }

.maincontent {
  /*height: 100%;*/
  height: 159px;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s; }


.container {
  max-width: 66.66667rem;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 95%; }
@media screen and (max-width: 750px) {
  .maincontent {
    height: 50px; }
  .container {
    width: 90%; } }

.title {
  text-align: center;
  font-size: 1.66667rem; }

.subtitle {
  font-family: 'Roboto-bold', Arial;
  font-weight: 600;
  font-size: 0.94444rem; }

.text, .text > ul {
  font-size: 0.88889rem;
  color: #272727;
  margin-bottom: 10px; }

.p-0 {
  padding: 0px !important;
}
.p-20 {
  padding: 20px !important;
}
.m-0 {
  margin: 0; }
.m-50 {
  margin: 50px; }

.mb-50 {
  margin-bottom: 50px !important; }
.mb-40 {
  margin-bottom: 40px; }
.mb-30 {
  margin-bottom: 30px !important; }
.mb-20 {
  margin-bottom: 20px; }
.mb-10 {
  margin-bottom: 10px; }
.mb-5 {
  margin-bottom: 5px; }
.mt-50 {
  margin-top: 50px; }
.mt-70 {
  margin-top: 70px; }
.mt-40 {
  margin-top: 40px; }
.mt-30 {
  margin-top: 30px !important; }
.mt-20 {
  margin-top: 20px; }
.mt-10 {
  margin-top: 10px; }
.mt-5 {
  margin-top: 5px; }
.mt-90 {
  margin-top: 90px; }
.mt-150 {
  margin-top: 150px; }

.text-primary {
  color: #4888ef; }
.text-white {
  color: #ffffff; }
.text-gray {
  color: #cccccc; }
.text-dark {
  color: #262626; }

ul.marked {
  margin-left: 15px; }
ul.marked li {
  list-style: disc; }

.panel {
  padding: 30px;
  border-radius: 5px;
  /*-webkit-box-shadow: 0 0 3px 1px #cccccc;*/
  /*box-shadow: 0 0 3px 1px #cccccc; */
}
.panel-rounded {
  border-radius: 30px;
}
.panel-bordered {
  border: 1px solid #cccccc;
}

.underlined::after {
  content: "";
  display: block;
  background: #5583DE;
  width: 100px;
  height: 3px;
  position: absolute; }

.btn {
  border-radius: 30px;
  color: white;
  font-size: 15px;
  font-family: "Roboto-reg";
  padding: 0.5rem 0.8rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  width: max-content;
}

.btn-lg {
  border-radius: 30px;
  padding: 1rem 1.5rem; }
.btn-xl {
  border-radius: 50px;
  padding: 1.5rem 2.5rem; }

.btn-primary {
  background-color: #5583DE;
  -webkit-box-shadow: 0px 4px 4px rgba(100, 160, 255, 0.25);
  box-shadow: 0px 4px 4px rgba(100, 160, 255, 0.25); }
.btn-primary:hover {
  background-color: #4888ef;
  cursor: pointer; }

.btn-warning {
  background-color: #ff8d00;
  -webkit-box-shadow: 0px 4px 4px rgba(255, 141, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(255, 141, 0, 0.25);
}
.btn-warning:hover {
  background-color: #ff8001;
  cursor: pointer; }

.btn-success {
  background-color: #61BD29;
  -webkit-box-shadow: 0px 4px 4px rgba(76, 175, 80, 0.25);
  box-shadow: 0px 4px 4px rgba(76, 175, 80, 0.25); }
.btn-success:hover {
  background-color: #58ad25;
  cursor: pointer; }

.no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center; }

.text-left {
  text-align: left !important; }

.text-size-big {
  font-size: 2rem; }

.text-size-medium {
  font-size: 1.5rem; }

.text-size-normal {
  font-size: 1rem; }

.text-size-mini {
  font-size: 0.8rem;
  line-height: 0.9rem; }

.text-size-small {
  font-size: 0.66667rem; }

.text-bold {
  font-weight: 500; }
.text-bolder {
  font-weight: 700; }
.text-underlined {
  text-decoration: underline;
}





.hamburger {
  display: none;
  position: absolute;
  right: 0;
  outline: none; }
@media screen and (max-width: 750px) {
  .hamburger {
    display: inline-block; } }
.hamburger.is-active {
  position: relative;
  z-index: 11;
  position: absolute;
  right: 0; }

.container--header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /*padding: 10px 0 0 0; */
}
@media screen and (max-width: 750px) {
  .container--header {
    padding: 0;
    height: 70px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; } }

.header {
  background-color: #ffffff; }
@media screen and (max-width: 750px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12; } }

.header__logo > img {
  width: auto;
}
@media screen and (max-width: 750px) {
  .header__logo > img {
    height: 40px !important;
  }
  .header__logo.disabled {
    display: none; } }

.header__logo-icon {
  width: 100%; }

.header__logo-pic {
  height: 38px;
  width: 126px; }

.header__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative; }
@media screen and (max-width: 750px) {
  .header__content {
    width: 100%; } }

.header__dashboard-btn {
  font-size: 0.77778rem; }
@media screen and (max-width: 750px) {
  .header__dashboard-btn {
    display: none !important; } }

.header__btn-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4D5267;
  margin-left: 20px; }
.header__btn-link img {
  width: 20px;
  height: 25px;
  margin-right: 10px; }

.header__dashboard-text {
  font-family: 'Roboto-med';
  font-size: 0.83333rem;
  color: #272727;
  margin-left: 15px; }

.header__lang-dropdown-sel-flag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.header__lang-drop-flag-icon {
  width: 34px;
  height: 34px; }

.header__lang {
  margin-left: 70px;
  font-family: 'Roboto-med';
  font-size: 14px; }
.header__lang .header__lang-dropdown-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  color: #272727; }

.header__lang-drop-icon {
  width: 14px;
  height: 7px; }

.header__lang-dropdown-menu {
  display: none; }

.cars tbody tr td {
  border: 1px solid #00a5ce; }
.cars tbody tr td img {
  width: 100%;
  padding: 10px; }

#first-screen {
  background: linear-gradient(126.8deg, #ffffff 17.86%, rgba(255, 255, 255, 0) 77.61%), #f8f8f8; }

.hero {
  padding: 3rem 0;
  background-image: none;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden !important;
  -webkit-box-shadow: inset 0 0 0 calc(100vh) #00000055;
  box-shadow: inset 0 0 0 calc(100vh) #00000055; }

.hero__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%; }
@media screen and (max-width: 750px) {
  .hero__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; } }

.hero__text-content {
  width: 45%; }
@media screen and (max-width: 750px) {
  .hero__text-content {
    width: 100%; } }

.hero__title {
  font-size: 40px;
  overflow-wrap: break-word;
  line-height: 1.15}

.hero__usage-description {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #ff9800; }
.hero__usage-description > span {
  font-size: 1.5rem;
}
@media screen and (max-width: 750px) {
  .hero__usage-description {
    width: 100% !important;
  } }

.hero__description {
  margin-top: 25px;
  font-size: 1rem;
  line-height: 1;
  color: #444;
}

.hero__btns {
  margin-top: 30px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.hero__btns > a, .hero__btns > button {
  font-size: 15px;
}
.hero__btns > a {
  padding: 4px 20px;
}
@media screen and (max-width: 750px) {
  .hero__btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

.hero__btn {
  border-radius: 25px;
  color: #FFFFFF;
  font-size: 15px;
  font-family: "Roboto-reg";
  padding: 1.11111rem 1.94444rem;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }
@media screen and (max-width: 768px) {
  .hero__btn {
    padding: 0.83333rem 0.83333rem; } }
@media screen and (max-width: 750px) {
  .hero__btn {
    padding: 1.11111rem 1.94444rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; } }

.hero__buy-btn {
  position: relative; }
@media screen and (max-width: 750px) {
  .hero__buy-btn {
    margin-top: 10px; } }

.hero__btn--download {
  background-color: #5583DE;
  display: inline-block;
}

.hero__btn--buy {
  background-color: #61BD29;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.hero__buy-button-icon {
  width: 15px; }
.hero__buy-button-icon img {
  width: 100%; }

.hero__buy-button-text {
  margin-left: 5px; }

.hero__discount-text {
  position: absolute;
  font-size: 15px;
  bottom: -130%; }
@media screen and (max-width: 750px) {
  .hero__discount-text {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); } }
.hero__discount-text span {
  font-family: "Roboto-med";
  font-weight: 900;
  text-transform: uppercase; }

.hero__discount-arrow {
  position: absolute;
  bottom: 7px;
  right: -30px; }

.hero__visual-content {
  width: 50%;
  -ms-flex-item-align: center;
  align-self: center; }
@media screen and (max-width: 750px) {
  .hero__visual-content {
    width: 100%; } }

@media screen and (max-width: 750px) {
  .hero__image {
    width: 100%; }
  .hero__image img {
    width: 100%; } }

.hero__platforms-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3.88889rem;
  width: 100%;
  -ms-flex-pack: distribute;
  justify-content: space-around; }
@media screen and (max-width: 750px) {
  .hero__platforms-list {
    display: none; } }

.hero__platforms-list-duble {
  display: none; }
@media screen and (max-width: 750px) {
  .hero__platforms-list-duble {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 3.88889rem;
    width: 100%; } }

.hero__platforms-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.83333rem; }
@media screen and (max-width: 750px) {
  .hero__platforms-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; } }

.hero__platforms-icon {
  height: 40px;
  width: 40px; }
.hero__platforms-icon.hero__platforms-icon--web {
  width: 70px; }

.hero__platforms-pic {
  width: 100%;
  height: 100%; }

.hero__platforms-text {
  margin-left: 8px;
  width: 45%; }
@media screen and (max-width: 750px) {
  .hero__platforms-text {
    margin-left: 0;
    margin-top: 20px;
    text-align: center; } }
.hero__platforms-text a {
  color: #5583DE; }


.menu {
  background-color: #ffffff;
  padding: 10px 0; }
@media screen and (max-width: 750px) {
  .menu {
    padding: 0; } }

.menu__list {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
.menu__item {
  padding:  0 6px;
  align-items: center;
  display: flex;
}
.menu__item-column {
  flex-direction: column;
}
.menu__list > li {
  display: flex;
}
@media screen and (max-width: 750px) {
  .menu__list {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 150%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    width: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition: left 0.3s;
    transition: left 0.3s;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 25% 10px; }
  .menu__list.active {
    left: 50%; } }

@media screen and (max-width: 750px) {
  .menu__item {
    margin-bottom: 20px; } }

.menu__item-link {
  font-family: 'Roboto-reg';
  font-size: 14px;
  color: #4D5267; }
@media screen and (max-width: 750px) {
  .menu__item-link {
    color: #272727;
    font-size: 14px; } }

label.pointer:hover {
  cursor: pointer; }

.modal {
  display: none; }

.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  will-change: opacity;
  background-color: #000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.modal__header {
  /*padding: 1.5rem;*/
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  /*border-bottom: 1px solid #ddd; */
}

.modal__close {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='15px' height='16px' viewBox='0 0 15 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='2.-Menu' transform='translate(-15.000000, -13.000000)' stroke='%23000000'%3E%3Cg id='Group' transform='translate(15.000000, 13.521000)'%3E%3Cpath d='M0,0.479000129 L15,14.2971819' id='Path-3'%3E%3C/path%3E%3Cpath d='M0,14.7761821 L15,-1.24344979e-14' id='Path-3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 15px;
  height: 15px;
  position: absolute;
}

.modal__wrapper {
  width: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 0;
  max-width: 500px;
  max-height: 80vh;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s;
  will-change: transform;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translateY(5%);
  transform: translateY(5%);
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */
  /* Optional */
  -webkit-box-shadow: 0 2px 6px #777;
  box-shadow: 0 2px 6px #777;
  border-radius: 5px;
  margin: 10px; }

.modal__content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0;/*1.5rem; */
}
.modal__title {
  margin: auto;
}

.modal.is-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999; }

.modal.is-visible .modal__wrapper {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.modal.is-visible .modal__overlay {
  opacity: 0.5; }

.navigation-ancors-wrap {
  min-height: 70px; }

.navigation-ancors {
  border-top: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  height: 3.88889rem;
  visibility: hidden;}
@media screen and (max-width: 750px) {
  .navigation-ancors {
    border: none;
    height: unset;
    visibility: visible;} }
.navigation-ancors .container--navigation-ancors {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
@media screen and (max-width: 750px) {
  .navigation-ancors .container--navigation-ancors {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 150%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    width: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition: left 0.3s;
    transition: left 0.3s;
    overflow: hidden; }
  .navigation-ancors .container--navigation-ancors.active {
    left: 50%; } }
.navigation-ancors .hero__btn {
  padding: 10px 15px; }
.navigation-ancors .hero__buy-btn {
  opacity: 0;
  right: -100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-size: 0.72222rem;
  -webkit-transition: right 0.3s, opacity 0.3s;
  transition: right 0.3s, opacity 0.3s;
  width: 35%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
@media screen and (max-width: 750px) {
  .navigation-ancors .hero__buy-btn {
    display: none; } }
.navigation-ancors .hero__discount-text {
  position: relative;
  margin-right: 0.55556rem;
  font-size: 10px; }
.navigation-ancors .hero__discount-arrow {
  position: absolute;
  bottom: -15px;
  right: -20px; }
.navigation-ancors .navigation-ancors__list {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 3.88889rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
@media screen and (max-width: 750px) {
  .navigation-ancors .navigation-ancors__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 100px;
    height: unset; } }
.navigation-ancors .navigation-ancors__item {
  margin-right: 0; }
.navigation-ancors .hero__discount-text {
  position: relative;
  top: 0; }
.navigation-ancors.fixed {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  visibility: visible;}
.navigation-ancors.fixed .hero__btn {
  opacity: 1;
  right: 0; }

.navigation-ancors__item {
  margin-right: 2.77778rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%; }
@media screen and (max-width: 750px) {
  .navigation-ancors__item {
    height: unset;
    padding: 20px 40px; } }
.navigation-ancors__item .hero__btn {
  position: relative;
  right: -3000px;
  -webkit-transition: right 0.3s, background-color 0.3s;
  transition: right 0.3s, background-color 0.3s; }

.navigation-ancors__link {
  font-family: 'Roboto-med';
  font-size: 0.83333rem; }
@media screen and (max-width: 768px) {
  .navigation-ancors__link {
    font-size: 0.66667rem; } }
@media screen and (max-width: 750px) {
  .navigation-ancors__link {
    font-size: 20px; } }



ul.benefits {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }
@media screen and (max-width: 768px) {
  ul.benefits {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; } }
ul.benefits li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 20%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
@media screen and (max-width: 768px) {
  ul.benefits li {
    width: 100%; } }
ul.benefits li img {
  max-width: 100px;
  max-height: 100px; }
ul.benefits li span {
  text-align: center;
  margin-top: 20px; }

.text-with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
.text-with-icon img {
  height: 32px;
  margin-right: 10px; }

@media screen and (max-width: 768px) {
  .text-with-icon {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
  .text-with-icon img {
    display: none; } }

@media screen and (max-width: 750px) {
  .hero__btns > a {
    margin-bottom: 10px;
    margin-right: 0 !important;
    text-align: center;
  }
  .hero__title {
    font-size: 1.5rem;
  }
  .hero__description > ul.marked > li {
    list-style: none !important;
  }
  .hero {
    box-shadow: inset 0 0 0 calc(100vh) #00000070;
  }
}
.btn-white {
  background-color: white;
  color: #5583DE;
  border: 1px solid white;
}
.ozon > img {
  transition: transform .4s;
}
.ozon:hover > img {
  transform: scale(1.1);
}



.circle-loader {
  margin-left: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #4888ef;
  animation-name: loader-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: content-box;
}

.circle-loader,
.circle-loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}

.load-error {
  -webkit-animation: none;
  animation: none;
  border-color: red;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
  box-sizing: content-box;
}
.checkmark.draw:after {
  opacity: 1;
  height: 1em;
  width: 0.5em;
  transform-origin: left top;
  border-right: 2px solid #5cb85c;
  border-top: 2px solid #5cb85c;
  content: "";
  left: 0.5em;
  top: 1em;
  position: absolute;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark.error {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  width: 1em;
  height: 1em;
}
.checkmark.error:before, .checkmark.error:after {
  position: absolute;
  content: " ";
  height: 1em;
  width: 2px;
  background-color: #f00;
}
.checkmark.error:before {
  transform: rotate(45deg);
}
.checkmark.error:after {
  transform: rotate(-45deg);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.5em;
    opacity: 1;
  }
  40% {
    height: 1em;
    width: 0.5em;
    opacity: 1;
  }
  100% {
    height: 1em;
    width: 0.5em;
    opacity: 1;
  }
}
.btn-primary-outline {
  color: #5583DE;
  border: 2px solid;
}
.btn-primary-outline:hover {
  color: white;
  background-color: #5583DE;
  border-color: #5583DE;
}
.btn-warning-outline {
  color: #ff9800;
  border: 2px solid;
}
.btn-warning-outline:not(.ozon):hover {
  color: white;
  background-color: #ff8001;
  border-color: #ff8001;
}
.btn-danger-outline {
  color: #f91155;
  border: 2px solid;
}
.btn-danger-outline:hover {
  color: white;
  background-color: #f91155;
  border-color: #f91155;
}

.mdl-textfield__label {
  color: #757575 !important;
}

.ozon-buy-primary-btn {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.ozon-buy-primary-btn > span {
  white-space: nowrap;
  margin-right: 10px;
}
.ozon-buy-primary-btn > img {
  width: auto !important;
  height: 20px !important;
  margin: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}



.circle-loader {
  margin-left: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #4888ef;
  animation-name: loader-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: content-box;
}

.circle-loader,
.circle-loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}

.load-error {
  -webkit-animation: none;
  animation: none;
  border-color: red;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
  box-sizing: content-box;
}
.checkmark.draw:after {
  opacity: 1;
  height: 1em;
  width: 0.5em;
  transform-origin: left top;
  border-right: 2px solid #5cb85c;
  border-top: 2px solid #5cb85c;
  content: "";
  left: 0.5em;
  top: 1em;
  position: absolute;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark.error {
  position: absolute;
  /*left: 0.5em;*/
  /*top: 0.5em;*/
  /*width: 1em;*/
  height: 1em;
  margin: 6px 15px;
}
.checkmark.error:before, .checkmark.error:after {
  position: absolute;
  content: " ";
  height: 1em;
  width: 2px;
  background-color: #f00;
  top: -6px;
  left: -7px;
}
.checkmark.error:before {
  transform: rotate(45deg);
}
.checkmark.error:after {
  transform: rotate(-45deg);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.5em;
    opacity: 1;
  }
  40% {
    height: 1em;
    width: 0.5em;
    opacity: 1;
  }
  100% {
    height: 1em;
    width: 0.5em;
    opacity: 1;
  }
}

.navigation-ancors-wrap {
  /*display: none;*/
}
@media screen and (max-width: 750px) {
  .navigation-ancors-wrap {
    display: block;
  }
}

img.size {
  height: 350px;
  width: fit-content;
}
.alert-warning {
  color: #ff9800;
  font-weight: 600;
  border-color: #ff9800 !important;
  border-width: 2px !important;
}
.CookieConsent {
  max-width: 450px;
  align-items: center !important;
  background-color: #34393f !important;
}
.pointer:hover {
  cursor: pointer;
}
.badge {
  padding: 4px 8px;
  border-radius: 50px;
  display: inline-block;
  width: max-content;
  font-family: 'Roboto-reg';
  font-size: 14px;
}
.badge-border {
  border: 1px solid;
}
.badge-outline-white {
  color: #ffffff;
  border: 1px solid #ffffff;
}
.badge-primary {
  background-color: #EFF3F9;
  color: #5583DE;
}

.landing-block {
  padding: 0px !important;
}
iframe {
  width: 100%;
  display: block;
}
.modal__content > iframe {
  height: 450px !important;
}
.center-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}
.hr-text {
  color: #eee;
  clear: both;
  overflow: hidden;
}
.hr-text > div {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.hr-text > div:before {
  margin-right: 0.6em;
  right: 100%;
  content: '';
  position: absolute;
  top: 50%;
  height: 0;
  width: 100vw;
  border-top-width: 1px;
  border-top-style: solid;
  color: #eee;
}
.hr-text > div:after {
  left: 100%;
  margin-left: 0.6em;
  content: '';
  position: absolute;
  top: 50%;
  height: 0;
  width: 100vw;
  border-top-width: 1px;
  border-top-style: solid;
  color: #eee;
}
.hr-text > div > h4 {
  margin: 20px 0;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.bg-light-blue {
  background-color: #EFF3F9;
}
.border-20 {
  border-radius: 20px;
}
.align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
ul.list-checked-primary > li {
  padding: 0px 0px 0px 10px;
  vertical-align: middle;
  list-style-image: url(img/icon-check-primary.svg);
  margin-bottom: 10px;
}