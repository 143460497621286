.horizontal-text-with-image {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-self: center;
    align-items: center;
    height: 100%;
}
.horizontal-text-with-image > img {
    width: 80px;
    height: 80px;
}
.horizontal-text-with-image > span {
    font-size: 15px;
    align-items: center;
    display: flex;
}
.vertical-text-with-image > h6 {
    font-size: 20px;
    font-weight: 600;
}
.vertical-text-with-image > span {
    font-size: 17px;
}